import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a2473c3 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _a1942bca = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _645069dc = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _2455a63f = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6f4bc6c0 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _fa9fef60 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _40f5c75f = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _effb1742 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _add59bc8 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _1f1c59fe = () => interopDefault(import('../src/pages/travel-extras.vue' /* webpackChunkName: "pages/travel-extras" */))
const _5002ac18 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _a798cd3a = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _4408e520 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _0c17087c = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _d97fdcb8 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _01480302 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _7fbaa96e = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _4a5d223f = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _546c34ee = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _974b474c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _0a2473c3,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _a1942bca,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _645069dc,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _2455a63f,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _6f4bc6c0,
    name: "receipt"
  }, {
    path: "/search",
    component: _fa9fef60,
    name: "search"
  }, {
    path: "/search-booking",
    component: _40f5c75f,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _effb1742,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _add59bc8,
    name: "terms-and-conditions"
  }, {
    path: "/travel-extras",
    component: _1f1c59fe,
    name: "travel-extras"
  }, {
    path: "/unsupported-browser",
    component: _5002ac18,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _a798cd3a,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _4408e520,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _0c17087c,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _d97fdcb8,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _01480302,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _7fbaa96e,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _4a5d223f,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _546c34ee,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _974b474c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
